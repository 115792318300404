'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Gatsby = require("gatsby");
var Page$Paulshen = require("../ui/Page.bs.js");
var Text$Paulshen = require("../ui/Text.bs.js");
var Fonts$Paulshen = require("../constants/Fonts.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");
var GatsbyPluginMdx = require("gatsby-plugin-mdx");
var MediaQuery$Paulshen = require("../constants/MediaQuery.bs.js");

var root = Css.style(/* [] */0);

var title = Css.style(/* :: */[
      Css.fontSize(Css.px(32)),
      /* :: */[
        Css.fontWeight(Css.medium),
        /* :: */[
          Css.margin3(Css.zero, Css.auto, Css.px(8)),
          /* :: */[
            Css.maxWidth(Css.px(600)),
            /* [] */0
          ]
        ]
      ]
    ]);

var date = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary400),
      /* :: */[
        Css.fontFamily(Fonts$Paulshen.mono),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.textTransform(Css.uppercase),
            /* :: */[
              Css.margin3(Css.zero, Css.auto, Css.px(32)),
              /* :: */[
                Css.maxWidth(Css.px(600)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var mdx = Css.style(/* :: */[
      Css.marginBottom(Css.px(64)),
      /* :: */[
        Css.selector("> *", /* :: */[
              Css.marginLeft(Css.auto),
              /* :: */[
                Css.marginRight(Css.auto),
                /* [] */0
              ]
            ]),
        /* :: */[
          Css.selector("p", /* :: */[
                Css.color(Colors$Paulshen.primary100),
                /* :: */[
                  Css.fontSize(Css.px(14)),
                  /* :: */[
                    Css.lineHeight(Css.em(1.5)),
                    /* :: */[
                      Css.maxWidth(Css.px(600)),
                      /* :: */[
                        MediaQuery$Paulshen._840(/* :: */[
                              Css.fontSize(Css.px(14)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]),
          /* :: */[
            Css.selector("h2", /* :: */[
                  Css.fontSize(Css.px(22)),
                  /* :: */[
                    Css.fontWeight(Css.medium),
                    /* :: */[
                      Css.marginTop(Css.em(1.5)),
                      /* :: */[
                        Css.marginBottom(Css.em(0.6)),
                        /* :: */[
                          Css.maxWidth(Css.px(600)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]),
            /* :: */[
              Css.selector("h3", /* :: */[
                    Css.fontSize(Css.px(18)),
                    /* :: */[
                      Css.fontWeight(Css.medium),
                      /* :: */[
                        Css.marginTop(Css.em(1.5)),
                        /* :: */[
                          Css.marginBottom(Css.em(0.6)),
                          /* :: */[
                            Css.maxWidth(Css.px(600)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                Css.selector("ul", /* :: */[
                      Css.maxWidth(Css.px(600)),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.selector("li", /* :: */[
                        Css.fontSize(Css.px(14)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.5)),
                          /* [] */0
                        ]
                      ]),
                  /* :: */[
                    Css.selector(".gatsby-highlight", /* :: */[
                          Css.maxWidth(Css.px(628)),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.selector("pre", /* :: */[
                            Css.margin2(Css.em(1), Css.zero),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector("pre code", /* :: */[
                              Css.fontSize(Css.px(14)),
                              /* :: */[
                                Css.lineHeight(Css.em(1.5)),
                                /* :: */[
                                  MediaQuery$Paulshen._840(/* :: */[
                                        Css.fontSize(Css.px(14)),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        /* :: */[
                          Css.selector("blockquote", /* :: */[
                                Css.backgroundColor(Colors$Paulshen.primary490),
                                /* :: */[
                                  Css.boxSizing(Css.borderBox),
                                  /* :: */[
                                    Css.marginBottom(Css.px(16)),
                                    /* :: */[
                                      Css.padding2(Css.px(16), Css.px(32)),
                                      /* :: */[
                                        Css.maxWidth(Css.px(600)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]),
                          /* :: */[
                            Css.selector("blockquote p:last-child", /* :: */[
                                  Css.marginBottom(Css.zero),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.selector("a", /* :: */[
                                    Css.color(Colors$Paulshen.primary100),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var footer = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary200),
      /* :: */[
        Css.borderTop(Css.px(1), Css.solid, Colors$Paulshen.primary450),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.paddingTop(Css.px(16)),
            /* :: */[
              Css.margin2(Css.zero, Css.auto),
              /* :: */[
                Css.maxWidth(Css.px(600)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  root: root,
  title: title,
  date: date,
  mdx: mdx,
  footer: footer
};

var MDXRenderer = { };

function PostPage(Props) {
  var data = Props.data;
  return React.createElement(Page$Paulshen.make, {
              children: React.createElement("div", {
                    className: root
                  }, React.createElement(Text$Paulshen.Header.make, {
                        children: data.mdx.frontmatter.title,
                        size: /* S3 */18560,
                        tag: /* H1 */16105,
                        className: title
                      }), React.createElement("div", {
                        className: date
                      }, data.mdx.frontmatter.date), React.createElement("div", {
                        className: mdx
                      }, React.createElement(GatsbyPluginMdx.MDXRenderer, {
                            children: data.mdx.body
                          })), React.createElement("div", {
                        className: footer
                      }, "Browse ", React.createElement(Gatsby.Link, {
                            to: "/",
                            children: "posts"
                          }), ". Get new post updates on ", React.createElement("a", {
                            href: "https://twitter.com/_paulshen"
                          }, "twitter"), ".")),
              title: data.mdx.frontmatter.title
            });
}


const {graphql} = require('gatsby');
exports.query = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date(formatString: "MMM D, YYYY")
      }
    }
  }
`;

;

var make = PostPage;

var $$default = PostPage;

exports.Styles = Styles;
exports.MDXRenderer = MDXRenderer;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* root Not a pure module */
